@import '../../../styles/propertySets.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  /**marketplaceModalTitleStyles*/  
   font-weight: var(--fontWeightBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
/** end */;
}

.field {
  margin: 25px 0 24px 0;

  @media (--viewportMedium) {
    margin: 22px 0 24px 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
